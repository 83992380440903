import React, {useEffect, useState} from "react";
import {ScrollView, View} from "react-native";
import {Card, Paragraph, TextInput} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";

import {
    isRequired,
    PhoneType,
    PhoneTypes,
    usPhoneNumber,
    UsStates
} from "@luminate/luminate-ts-sdk";
import { DropDown } from "@luminate/luminate-native-components";

import {EditProfileScreenNavigationProps} from "../routes/ProfileStack";
import {ValidatedInput} from "../common/ValidatedInput";
import {useAuth} from "../../contexts/AuthContext";
import {Dialog} from "../common/Dialog";
import {Header30, SubText} from "../typography";
import {Button} from "../common/Button";
import {useThemeContext} from "../../contexts/ThemeContext";
import {PageFooter} from "../common/PageFooter";

export const EditProfileScreen = () => {
    const navigation = useNavigation<EditProfileScreenNavigationProps>();
    const {theme} = useThemeContext();
    const {patientProfile, updatePatientProfile, updatePatientGender, displaySnack, genders} = useAuth();
    const [address1, setAddress1] = useState<string>();
    const [address1Errors, setAddress1Errors] = useState<Array<string>>([]);
    const [address1Valid, setAddress1Valid] = useState(true);
    const [gender, setGender] = useState<string>();
    const [showGenderDropdown, setShowGenderDropdown] = useState(false);

    const mustSpecifyGender = () => !patientProfile?.gender || patientProfile.gender === 'U';
    const setGenderDropdown = () => {
        setShowGenderDropdown(!showGenderDropdown);
      };

    const onAddress1Changed = (value: string) => {
        setAddress1(value);
    };

    const onAddress1Validated = (errors: Array<string>) => {
        setAddress1Errors(errors);
        setAddress1Valid(errors.length === 0);
    };

    const [city, setCity] = useState<string>();
    const [cityErrors, setCityErrors] = useState<Array<string>>([]);
    const [cityValid, setCityValid] = useState(true);

    const onCityChanged = (value: string) => {
        setCity(value);
    };

    const onCityValidated = (errors: Array<string>) => {
        setCityErrors(errors);
        setCityValid(errors.length === 0);
    };

    const [zip, setZip] = useState<string>();
    const [zipErrors, setZipErrors] = useState<Array<string>>([]);
    const [zipValid, setZipValid] = useState(true);

    const onZipChanged = (value: string) => {
        setZip(value);
    };

    const onZipValidated = (errors: Array<string>) => {
        setZipErrors(errors);
        setZipValid(errors.length === 0);
    };

    const [phone, setPhone] = useState<string>();
    const [phoneErrors, setPhoneErrors] = useState<Array<string>>([]);
    const [phoneValid, setPhoneValid] = useState(false);

    const onPhoneChanged = (value: string) => {
        setPhone(value);
    };

    const onPhoneValidated = (errors: Array<string>) => {
        setPhoneErrors(errors);
        setPhoneValid(errors.length === 0);
    };

    const [phoneType, setPhoneType] = useState<PhoneType | null>();

    useEffect(() => {
        setShowPhoneDropDown(phone => !phone);
    }, []);

    const isFormValid = address1Valid && cityValid && zipValid && phoneValid;

    const [showStateDropDown, setShowStateDropDown] = useState(false);
    const [state, setState] = useState<string>();

    const [address2, setAddress2] = useState<string>();
    const onAddress2Changed = (value: string) => {
        setAddress2(value);
    };

    const setStateDropdown = () => {
        setShowStateDropDown(!showStateDropDown);
    };

    const [showPhoneDropDown, setShowPhoneDropDown] = useState(false);

    const setPhoneDropdown = () => {
        setShowPhoneDropDown(!showPhoneDropDown);
    };

    const loadProfile = async () => {
        setAddress1(patientProfile?.address1);
        setAddress2(patientProfile?.address2);
        setCity(patientProfile?.city);
        setState(patientProfile?.state);
        setZip(patientProfile?.zip);
        setPhone(patientProfile?.phone);
        setPhoneType(patientProfile?.phoneType ? patientProfile?.phoneType : PhoneType.MOBILE);
    };

    useEffect(() => {
        loadProfile();
    }, []);

    const onSaveChanges = async () => {
        try {
            await updatePatientProfile(address1 as string, city as string, state as string, zip as string, phone as string, phoneType as PhoneType, address2);

            if (gender) {
                await updatePatientGender(gender);
            }
            navigation.pop();
        } catch (ex) {
            displaySnack(
                <SubText style={{color: theme.colors.surface}}>We were unable to save the changes to your
                    profile. Please try again later.</SubText>
            );
        }
    };

    const onDiscardChanges = () => {
        navigation.pop();
    };

    const getQualifiedGenders = () => {
        return genders.filter(item => item.label.toUpperCase().startsWith('F') || item.label.toUpperCase().startsWith('M'));
    }

    return (
        <ScrollView style={{backgroundColor: theme.colors.secondaryAccent}}>
            <Dialog style={{marginVertical: 20}}
                    actions={<View style={{
                        flex: 1,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Button style={{margin: 5}} disabled={!isFormValid} onPress={onSaveChanges}>Update My
                            Profile</Button>
                        <Button style={{margin: 5}} onPress={onDiscardChanges}>Discard Changes</Button>
                    </View>}
            >
                <View style={{flex: 1, alignItems: 'center', marginVertical: 5}}>
                    <Header30 style={{marginVertical: 10}}>Edit My Account</Header30>
                </View>
                <View>
                    {mustSpecifyGender() &&
                        <Card style={{zIndex: 1, backgroundColor: theme.colors.primary}}>
                            <Card.Content>
                                <Paragraph style={{color: theme.colors.surface}}>
                                    Ordering tests online requires that you specify your gender at the
                                    time of birth.
                                </Paragraph>
                                <DropDown
                                    placeholder={'Gender'}
                                    style={{ marginVertical: 5 }}
                                    isOpen={showGenderDropdown}
                                    value={gender}
                                    items={getQualifiedGenders()}
                                    onOpen={setGenderDropdown}
                                    onDismiss={setGenderDropdown}
                                />
                            </Card.Content>
                        </Card>
                    }
                    <ValidatedInput
                        label={'Address'}
                        errors={address1Errors}
                        value={address1}
                        rules={[isRequired]}
                        onChangeText={onAddress1Changed}
                        onValidate={onAddress1Validated}
                        mode={'outlined'}
                        style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                    />
                    <TextInput label="Address (Continued)"
                               value={address2}
                               onChangeText={onAddress2Changed}
                               mode={'outlined'}
                               style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                    />
                    <ValidatedInput
                        label={'City'}
                        errors={cityErrors}
                        value={city}
                        rules={[isRequired]}
                        onChangeText={onCityChanged}
                        onValidate={onCityValidated}
                        mode={'outlined'}
                        style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                    />
                    <DropDown
                        placeholder='State'
                        position='top'
                        isOpen={showStateDropDown}
                        items={UsStates}
                        value={state}
                        onOpen={setStateDropdown}
                        onDismiss={setStateDropdown}
                    />
                    <ValidatedInput
                        label="Zip"
                        value={zip}
                        onChangeText={onZipChanged}
                        rules={[isRequired]}
                        errors={zipErrors}
                        onValidate={onZipValidated}
                        mode={'outlined'}
                        style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                    />
                       <DropDown
                        placeholder='Phone Type'
                        position='top'
                        isOpen={showPhoneDropDown}
                        items={PhoneTypes}
                        value={phoneType ?? ''}
                        onOpen={setPhoneDropdown}
                        onDismiss={setPhoneDropdown}
                    />
                    <ValidatedInput
                        label="Phone Number"
                        value={phone}
                        onChangeText={onPhoneChanged}
                        rules={[usPhoneNumber]}
                        errors={phoneErrors}
                        onValidate={onPhoneValidated}
                        mode={'outlined'}
                        style={{backgroundColor: theme.colors.surface, marginVertical: 5}}
                    />
                </View>
            </Dialog>
            <PageFooter/>
        </ScrollView>
    );
};