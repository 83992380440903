import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { HelperText, TextInput } from 'react-native-paper';

import { DateUtilities, isRequired, mustBe5Digits, PhoneTypes, RegistrationRequest, usPhoneNumber, UsStates } from '@luminate/luminate-ts-sdk';
import { DropDown } from '@luminate/luminate-native-components';

import { useAuth } from '../../contexts/AuthContext';
import { useRegistrationContext } from '../../contexts/RegistrationContext';
import { useThemeContext } from '../../contexts/ThemeContext';
import { Button } from '../common/Button';
import { Dialog } from '../common/Dialog';
import { PageFooter } from '../common/PageFooter';
import { ValidatedInput } from '../common/ValidatedInput';
import { SCREEN_NAMES } from '../routes/AppStackWeb';
import { Header40, SubText } from '../typography';

export const ConnectHealthDataScreen = () => {
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    email,
    userName,
    password,
    guardianRequest,
    guardianFirstName,
    guardianLastName,
    guardianDateOfBirth,
    agreeToTerms,
    gender,
    setGender,
    last4,
    setLast4,
    phoneType,
    setPhoneType,
    phone,
    setPhone,
    addressOne,
    setAddressOne,
    addressTwo,
    setAddressTwo,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
  } = useRegistrationContext();
  const { register, genders } = useAuth();
  const navigation = useNavigation();
  const { theme } = useThemeContext();

  const [showGenderDropdown, setShowGenderDropdown] = useState(false);
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(false);

  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [phoneErrors, setPhoneErrors] = useState(new Array<string>());

  const [isAddressOneValid, setIsAddressOneValid] = useState(false);
  const [addressOneErrors, setAddressOneErrors] = useState(new Array<string>());

  const [isCityValid, setIsCityValid] = useState(false);
  const [cityErrors, setCityErrors] = useState(new Array<string>());

  const [showStateDropdown, setShowStateDropdown] = useState(false);

  const [isZipValid, setIsZipValid] = useState(false);
  const [zipErrors, setZipErrors] = useState(new Array<string>());

  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const [registrationErrorMessage, setRegistrationErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setShowPhoneDropdown((phone) => !phone);
  }, [phoneType]);

  const isFormValid = () => {
    return isPhoneValid && isAddressOneValid && isCityValid && isZipValid && phoneType && gender && state;
  };

  const onBackToRegistration = () => {
    // @ts-ignore
    navigation.navigate(SCREEN_NAMES.REGISTER);
  };

  const onConnect = async () => {
    setIsRegistering(true);
    try {
      const validatedLast4 = last4 && last4.trim().length > 0 ? last4 : undefined;
      await register({
        firstName,
        middleName,
        lastName,
        dateOfBirth: DateUtilities.fromString(dateOfBirth),
        email,
        userName,
        password,
        guardianRequest,
        guardianFirstName,
        guardianLastName,
        guardianDateOfBirth: DateUtilities.fromString(guardianDateOfBirth),
        gender,
        ssn4: validatedLast4,
        phoneType,
        phone,
        address1: addressOne,
        address2: addressTwo,
        state,
        city,
        zip,
        agreeToTerms,
      } as RegistrationRequest);
    } catch (ex) {
      setRegistrationError(true);
      // @ts-ignore
      setRegistrationErrorMessage(ex.message);
    } finally {
      setIsRegistering(false);
    }
  };

  const setGenderDropdown = () => {
    setShowGenderDropdown(!showGenderDropdown);
  };

  const onLast4Changed = (value: string) => {
    setLast4(value);
  };

  const setPhoneDropdown = () => {
    setShowPhoneDropdown(!showPhoneDropdown);
  };
  const onPhoneChanged = (value: string) => {
    setPhone(value);
  };
  const onPhoneValidated = (errors: Array<string>) => {
    setIsPhoneValid(errors.length === 0);
    setPhoneErrors(errors);
  };

  const onAddressOneChanged = (value: string) => {
    setAddressOne(value);
  };
  const onAddressOneValidated = (errors: Array<string>) => {
    setIsAddressOneValid(errors.length === 0);
    setAddressOneErrors(errors);
  };
  const onAddressTwoChanged = (value: string) => {
    setAddressTwo(value);
  };

  const onCityChanged = (value: string) => {
    setCity(value);
  };
  const onCityValidated = (errors: Array<string>) => {
    setIsCityValid(errors.length === 0);
    setCityErrors(errors);
  };

  const setStateDropdown = () => {
    setShowStateDropdown(!showStateDropdown);
  };

  const onZipChanged = (value: string) => {
    setZip(value);
  };
  const onZipValidated = (errors: Array<string>) => {
    setIsZipValid(errors.length === 0);
    setZipErrors(errors);
  };

  return (
    <>
      <ScrollView style={{ backgroundColor: theme.colors.secondaryAccent }}>
        <Dialog
          contentStyle={{ zIndex: 1 }}
          style={{ marginVertical: 20 }}
          actions={
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                style={{ marginHorizontal: 5, marginVertical: 10 }}
                disabled={!isFormValid() || isRegistering}
                loading={isRegistering}
                onPress={onConnect}
              >
                Connect
              </Button>
              <Button style={{ marginHorizontal: 5, marginVertical: 10 }} onPress={onBackToRegistration}>
                Back
              </Button>
            </View>
          }
        >
          <View style={{ flex: 1, alignItems: 'center', marginVertical: 5 }}>
            <Header40>Connect My Health Data</Header40>
          </View>
          <View>
            <SubText style={{ textAlign: 'center', marginVertical: 10, marginLeft: 10 }}>All fields are required unless noted.</SubText>
            <DropDown
              style={{ zIndex: 5 }}
              placeholder="Gender"
              isOpen={showGenderDropdown}
              items={genders}
              value={gender}
              onOpen={setGenderDropdown}
              onDismiss={setGenderDropdown}
            />
            <TextInput
              label="Last Four of SSN (Optional)"
              mode={'outlined'}
              style={{ backgroundColor: theme.colors.surface, marginVertical: 5 }}
              value={last4}
              onChangeText={onLast4Changed}
            />
            <DropDown
              placeholder="Phone Type"
              isOpen={showPhoneDropdown}
              items={PhoneTypes}
              value={phoneType}
              onOpen={setPhoneDropdown}
              onDismiss={setPhoneDropdown}
            />
            <ValidatedInput
              label="Phone Number"
              mode={'outlined'}
              style={{ backgroundColor: theme.colors.surface, marginVertical: 5 }}
              value={phone}
              onChangeText={onPhoneChanged}
              rules={[usPhoneNumber]}
              errors={phoneErrors}
              onValidate={onPhoneValidated}
            />
            <ValidatedInput
              label="Street Address"
              mode={'outlined'}
              style={{ backgroundColor: theme.colors.surface, marginVertical: 5 }}
              value={addressOne}
              onChangeText={onAddressOneChanged}
              rules={[isRequired]}
              errors={addressOneErrors}
              onValidate={onAddressOneValidated}
            />
            <TextInput
              label="Address (Continued)"
              mode={'outlined'}
              style={{ backgroundColor: theme.colors.surface, marginVertical: 5 }}
              value={addressTwo}
              onChangeText={onAddressTwoChanged}
            />
            <ValidatedInput
              label="City"
              mode={'outlined'}
              style={{ backgroundColor: theme.colors.surface, marginVertical: 5 }}
              value={city}
              onChangeText={onCityChanged}
              rules={[isRequired]}
              errors={cityErrors}
              onValidate={onCityValidated}
            />
            <DropDown
              placeholder="State"
              position="top"
              isOpen={showStateDropdown}
              items={UsStates}
              value={state}
              onOpen={setStateDropdown}
              onDismiss={setStateDropdown}
            />
            <ValidatedInput
              label="Zip"
              mode={'outlined'}
              style={{ backgroundColor: theme.colors.surface, marginVertical: 5 }}
              value={zip}
              onChangeText={onZipChanged}
              rules={[isRequired, mustBe5Digits]}
              errors={zipErrors}
              onValidate={onZipValidated}
            />
            <HelperText type={'error'} visible={registrationError}>
              {registrationErrorMessage ? registrationErrorMessage : 'Unable to register account at this time.'}
            </HelperText>
          </View>
        </Dialog>
        <PageFooter />
      </ScrollView>
    </>
  );
};
