import React, {useEffect, useState} from "react";
import {FlatList, ListRenderItemInfo, ScrollView, View} from "react-native";
import {ActivityIndicator, Card, HelperText, Surface} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {StripeCardElement} from "@stripe/stripe-js/types/stripe-js/elements";
import {MaterialIcons} from "@expo/vector-icons";
import {usePubNub} from "pubnub-react";
import {PubnubData} from "pubnub";

import {
    CartTest,
    isRequired,
    OrderStatusNotificationDtoToOrderStatusNotificationConverter,
    PreCheckoutStatus
} from "@luminate/luminate-ts-sdk";

import {ValidatedInput} from "../common/ValidatedInput";
import {CartTestSummary, PurchaseSummary, StripeCardWeb} from "../cart";
import {StripeService} from "../../services/StripeService";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAuth} from "../../contexts/AuthContext";
import {CheckoutScreenWebNavigationScreenProps} from "../routes/CartStackWeb";
import {AuthStack} from "../routes/AuthStack";
import {Header18, Header30, SubText} from "../typography";
import {Button} from "../common/Button";
import {PageFooter} from "../common/PageFooter";
import {useResponsive} from "../hooks/useResponsive";
import {AnalyticsService, BeginCheckoutEvent} from "../../services/AnalyticsService";
import {TestToAnalyticsItemConverter} from "../../services/converter/TestToAnalyticsItemConverter";
import {SCREEN_NAMES} from "../routes/AppStackWeb";

export const CheckoutScreenWeb = () => {
    const {theme} = useThemeContext();
    const {
        authData,
        patientProfile,
        session,
        cart,
        setCart,
        addNewOrder,
        submitPayment,
        assignCart,
        performPrecheckoutValidation,
        displaySnack
    } = useAuth();
    const navigation = useNavigation<CheckoutScreenWebNavigationScreenProps>();
    const [stripeService, setStripeService] = useState<StripeService>();

    const pubnub = usePubNub();

    const [firstName, setFirstName] = useState(patientProfile?.firstName);
    const [firstNameValid, setFirstNameValid] = useState(false);
    const [firstNameErrors, setFirstNameErrors] = useState(new Array<string>());

    const [lastName, setLastName] = useState(patientProfile?.lastName);
    const [lastNameValid, setLastNameValid] = useState(false);
    const [lastNameErrors, setLastNameErrors] = useState(new Array<string>());

    const onFirstNameValidated = (errors: Array<string>) => {
        setFirstNameErrors(errors);
        setFirstNameValid(errors.length === 0);
    };
    const onFirstNameChanged = (value: string) => {
        setFirstName(value);
    };

    const onLastNameValidated = (errors: Array<string>) => {
        setLastNameErrors(errors);
        setLastNameValid(errors.length === 0);
    };
    const onLastNameChanged = (value: string) => {
        setLastName(value);
    };

    const [isCardLoading, setIsCardLoading] = useState(false);
    const [errorLoadingCard, setErrorLoadingCard] = useState(false);
    const [cardLoadErrorMessage, setCardLoadErrorMessage] = useState();
    const [card, setCard] = useState<StripeCardElement>();
    const [subscribed, setSubscribed] = useState(false);
    const [isCardValid, setIsCardValid] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [preCheckoutStatus, setPreCheckOutStatus] = useState<PreCheckoutStatus>({
        validTelecom: true,
        validResidency: true,
        validGender: true,
        validAge: true,
        testsFailingGenderCheck: []
    });

    const precheckoutPass = () => {
        return preCheckoutStatus.validAge &&
            preCheckoutStatus.validResidency &&
            preCheckoutStatus.validGender &&
            preCheckoutStatus.validTelecom &&
            preCheckoutStatus.testsFailingGenderCheck.length === 0;
    };

    const GenderLabel = (gender: string): string => {
        return gender === 'M' ? 'Male' : gender === 'F' ? 'Female' : 'Unknown';
    };

    const OppositeGenderLabel = (gender: string): string => {
      return GenderLabel(gender === 'M' ? 'F' : gender === 'F' ? 'M' : 'U');
    };

    const isFormValid = firstNameValid &&
        lastNameValid &&
        isCardValid &&
        precheckoutPass();

    const onPubNubMessageEvent = (event: PubnubData) => {
        const converted = OrderStatusNotificationDtoToOrderStatusNotificationConverter.create().toModel(JSON.parse(event.message));
        pubnub.unsubscribeAll();
        setSubscribed(false);
        addNewOrder(converted.order);
        setCart(undefined);
        navigation.navigate('OrderConfirmation');
    };

    const onCardValidation = (isValid: boolean) => {
        setIsCardValid(isValid);
    };

    const loadCard = async () => {
        setIsCardLoading(true);
        try {
            const instance = await StripeService.create(session?.lab?.id as number);
            setStripeService(instance);
            setCard(await instance.createCard(patientProfile?.zip));
        } catch (ex) {
            setCardLoadErrorMessage(ex.message ? ex.message : ex);
            setErrorLoadingCard(true);
        } finally {
            setIsCardLoading(false);
        }
    };

    const loadPreCheckoutStatus = async () => {
        try {
            const response = await performPrecheckoutValidation();
            setPreCheckOutStatus(response);
        } catch (ex) {
            /*
             * Need to catch any errors with the pre-checkout validation and prevent the user from being able to proceed
             * until the validation can take place.
             * ToDo: What should this look like?  Just that the user's profile appears to be invalid?  This could be confusing
             * but otherwise ?
             */
            setPreCheckOutStatus({
                validResidency: false,
                validTelecom: false,
                validGender: false,
                validAge: false,
                testsFailingGenderCheck: []
            });
        }
    };

    const subScribePubNubChannel = async () => {
        if (!subscribed) {
            setSubscribed(true);
            pubnub.addListener({message: onPubNubMessageEvent});
            pubnub.subscribe({channels: [cart?.transactionId as string]});
        }
    };

    const sendBeginCheckoutAnalyticsEvent = () => {
        AnalyticsService.create().sendEvent(
            new BeginCheckoutEvent(
                session?.lab.id as number,
                //@ts-ignore
                cart?.totals / 100,
                //@ts-ignore
                cart?.cartTests.map(test => TestToAnalyticsItemConverter(test)),
                authData?.patientId,
            )
        );
    };

    useEffect(() => {
        subScribePubNubChannel();
    }, [pubnub]);

    useEffect(() => {
        loadCard();
        sendBeginCheckoutAnalyticsEvent();
    }, []);

    useEffect(() => {
        if (authData) {
            assignCart();
        }
    }, [authData]);

    /*
     * Update Checkout Screen State to show the First Name, Last Name and Zip code in the billing information
     * for newly created accounts;
     */
    useEffect(() => {
        if(patientProfile) {
            setFirstName(patientProfile.firstName);
            setLastName(patientProfile.lastName);
            loadCard();
        }
    }, [patientProfile]);

    useEffect(() => {
        if (cart?.patientId) {
            loadPreCheckoutStatus();
        }
    }, [cart?.patientId, patientProfile?.state, patientProfile?.phone, patientProfile?.gender]);

    const onPlaceOrder = async () => {
        setProcessing(true);
        try {
            if (card && stripeService) {
                await submitPayment(`${firstName} ${lastName}`, card, stripeService);
            }
        } catch (ex) {
            displaySnack(() => <SubText style={{color: theme.colors.surface}}>We were unable to process payment. Please
                check your card details or try again later.</SubText>);
            setProcessing(false);
        }
    };
    const renderCartTestSummary = (test: ListRenderItemInfo<CartTest>) => {
        return (
            <CartTestSummary key={test.item.testId}
                             id={test.item.testId}
                             name={test.item.name}
                             code={test.item.code}
                             description={test.item.shortDescription}
                             price={test.item.price}
                             invalid={preCheckoutStatus.testsFailingGenderCheck.some(failedTest => failedTest.testId === test.item.testId)}
                             onAfterRemovedFromCart={loadPreCheckoutStatus}
            />
        );
    };

    const getTestNamesForInvalidTests = (): Array<String> => {
        return preCheckoutStatus.testsFailingGenderCheck.map(test => test.name);
    };

    const {screenWidth} = useResponsive();
    const MAX_SIDE_BY_SIDE = 560;
    const MIN_SIDE_BY_SIDE = 270;
    const MAX_TOTAL = MAX_SIDE_BY_SIDE * 2;

    return (
        <>
            {!authData
                ? <AuthStack/>
                :
                <>
                    {!preCheckoutStatus.validAge
                        ? <Surface style={{
                            zIndex: 9,
                            backgroundColor: theme.colors.notification,
                            justifyContent: 'center',
                            flexDirection: 'row',
                            padding: 20,
                            alignItems: 'center'
                        }}>
                            <MaterialIcons name={'warning'} size={32} color={theme.colors.surface}/>
                            <SubText style={{
                                marginHorizontal: 20,
                                fontWeight: 'bold',
                                fontSize: 18,
                                maxWidth: 700,
                                textAlign: 'center',
                                color: theme.colors.surface
                            }}>
                                We apologize, but we will not be able to process your order. You must be 18 years or
                                older to purchase tests from this site. If you need support please click <SubText
                                style={{color: theme.colors.surface, textDecorationLine: 'underline'}}
                                onPress={() => navigation.navigate(SCREEN_NAMES.CONTACT_US)}>here</SubText>.
                            </SubText>
                            <MaterialIcons name={'warning'} size={32} color={theme.colors.surface}/>
                        </Surface>
                        : <></>
                    }
                    {preCheckoutStatus.validAge && (!preCheckoutStatus.validGender || !preCheckoutStatus.validResidency || !preCheckoutStatus.validTelecom)
                        ? <Surface style={{
                            zIndex: 9,
                            backgroundColor: theme.colors.notification,
                            padding: 20,
                            alignItems: 'center'
                        }}>
                            <View style={{
                                justifyContent: 'center',
                                flexDirection: 'row'
                            }}>
                                <MaterialIcons name={'warning'} size={32} color={theme.colors.surface}/>
                                <SubText style={{
                                    marginHorizontal: 20,
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    color: theme.colors.surface
                                }}>
                                    You must update your profile.
                                </SubText>
                                <MaterialIcons name={'warning'} size={32} color={theme.colors.surface}/>
                            </View>
                            <SubText style={{fontSize: 15, color: theme.colors.surface}}>
                                <ul>
                                    {!preCheckoutStatus.validGender
                                        && <li>You must specify your gender at birth.</li>
                                    }
                                    {!preCheckoutStatus.validTelecom
                                        && <li>You must provide a valid telephone number.</li>
                                    }
                                    {!preCheckoutStatus.validResidency
                                        && <li>You must reside within the following state(s):&nbsp;
                                            {session?.appConfig.supportedStates.join(', ')}.</li>
                                    }
                                </ul>
                            </SubText>
                            <Button onPress={() => navigation.navigate('EditProfile')}>Edit My Profile</Button>
                        </Surface>
                        : <></>
                    }
                    {preCheckoutStatus.testsFailingGenderCheck.length > 0
                        ? <Surface style={{
                            zIndex: 9,
                            backgroundColor: theme.colors.notification,
                            padding: 20,
                            alignItems: 'center'
                        }}>
                            <View style={{
                                justifyContent: 'center',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <MaterialIcons name={'warning'} size={32} color={theme.colors.surface}/>
                                <SubText style={{
                                    marginHorizontal: 20,
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    color: theme.colors.surface
                                }}>
                                    Invalid Test Selection
                                </SubText>
                                <MaterialIcons name={'warning'} size={32} color={theme.colors.surface}/>
                            </View>
                            <SubText style={{color: theme.colors.surface, marginVertical: 10}}>
                                You've indicated {GenderLabel(patientProfile?.gender as string)} in your
                                profile. {getTestNamesForInvalidTests().join(', ')} can only be ordered
                                by a {OppositeGenderLabel(patientProfile?.gender as string)}, please remove it to
                                continue checkout. If you need support please click <SubText
                                style={{color: theme.colors.surface, textDecorationLine: 'underline'}}
                                onPress={() => navigation.navigate(SCREEN_NAMES.CONTACT_US)}>here</SubText>.
                            </SubText>
                        </Surface>
                        : <></>
                    }
                    <ScrollView>
                        <View style={{alignItems: 'center'}}>
                            <View style={{maxWidth: MAX_TOTAL}}>
                                <View style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap-reverse',
                                    justifyContent: 'space-evenly',
                                    padding: 5,
                                    width: '100%',
                                    maxWidth: MAX_TOTAL
                                }}>
                                    <View style={{
                                        flex: 3,
                                        width: '100%',
                                        minWidth: MIN_SIDE_BY_SIDE
                                    }}>
                                        <Header30 style={{paddingVertical: 5}}>Billing</Header30>
                                        <ValidatedInput label={'First Name'}
                                                        value={firstName}
                                                        mode={'outlined'}
                                                        style={{
                                                            marginVertical: 5,
                                                            marginHorizontal: 20,
                                                            backgroundColor: theme.colors.surface
                                                        }}
                                                        outlineColor={theme.colors.primary}
                                                        onChangeText={onFirstNameChanged}
                                                        rules={[isRequired]}
                                                        errors={firstNameErrors}
                                                        onValidate={onFirstNameValidated}
                                        />
                                        <ValidatedInput label={'Last Name'}
                                                        value={lastName}
                                                        mode={'outlined'}
                                                        style={{
                                                            marginVertical: 5,
                                                            marginHorizontal: 20,
                                                            backgroundColor: theme.colors.surface
                                                        }}
                                                        outlineColor={theme.colors.primary}
                                                        onChangeText={onLastNameChanged}
                                                        rules={[isRequired]}
                                                        errors={lastNameErrors}
                                                        onValidate={onLastNameValidated}
                                        />
                                        {isCardLoading
                                            ? <ActivityIndicator animating={true} size="large"/>
                                            : <>
                                                <StripeCardWeb card={card} onValidation={onCardValidation}/>
                                                <HelperText visible={errorLoadingCard}
                                                            type={'error'}
                                                            style={{color: theme.colors.error}}>{cardLoadErrorMessage}</HelperText>
                                            </>
                                        }

                                        <Header30 style={{paddingVertical: 5}}>Review Cart</Header30>
                                        <FlatList data={cart?.cartTests}
                                                  renderItem={renderCartTestSummary}
                                                  keyExtractor={(item) => item.testId.toString()}
                                        />
                                    </View>
                                    <View style={[{
                                        flex: 3,
                                        width: '100%',
                                        minWidth: MIN_SIDE_BY_SIDE
                                    }, screenWidth >= MAX_SIDE_BY_SIDE ? {paddingLeft: 10} : {paddingBottom: 10}]}>
                                        <Card style={{backgroundColor: theme.colors.secondaryAccent}}>
                                            <Card.Content>
                                                <Header18 style={{marginLeft: 10}}>Order Summary</Header18>
                                                <PurchaseSummary tests={cart?.cartTests || []}
                                                                 fees={cart?.fees || 0}
                                                                 taxes={cart?.taxes || 0}
                                                                 total={cart?.totals || 0}/>
                                            </Card.Content>
                                            <Card.Actions style={{justifyContent: 'center'}}>
                                                <Button disabled={!isFormValid || processing}
                                                        loading={processing}
                                                        key={'purchase_button'}
                                                        onPress={onPlaceOrder}>
                                                    Place Order
                                                </Button>
                                            </Card.Actions>
                                            <SubText style={{
                                                fontSize: 10,
                                                textAlign: 'left',
                                                padding: 20
                                            }}>{session?.appConfig.feesMsg}</SubText>
                                        </Card>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <PageFooter/>
                    </ScrollView>
                </>
            }
        </>
    );
};